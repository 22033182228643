import React, { Component } from 'react';
import { BrowserRouter as Router, Route, Switch, Redirect } from 'react-router-dom';
//context
import { AppContextProvider, AppContext } from './context/appStateContext'

//axios
import axios from './helperComponents/axios'
import Loader from './components/loader/Loader'
//antd
import { notification } from 'antd'
//helper functions
import { nthIndex } from './helperFunctions/functions'
//helper components
import 'antd/dist/antd.css'
import './App.scss';


const loading = () => <Loader isLoading={true} />

// Containers
const DefaultLayout = React.lazy(() => import('./containers/DefaultLayout'));
const Login = React.lazy(() => import('./views/login'));



class Main extends Component {

  UNSAFE_componentWillMount() {
    axios.interceptors.response.use(res => {

      if (res.data.message) {
        notification.success({
          message: res.data.message,
        });
      }
      return res
    }, error => {
      var serverError = false
      if (error.response) {
        if (error.response.status == 401) {
          this.props.context.deleteUserStorage()
        }
        if (!(error.response.message || error.response.data) || error.response.status == 404 || error.response.status == 405 || error.response.status >= 500) {
          serverError = true
        } else if (error.response.data.message && error.response.status != 422) {
          console.log("res.response",error.response)
          console.log("res.response",error)
          const word = error.response.config.url.split('#')[1];
          notification.error({
            message: 'ليس لديك صلاحية في '+word,
          });
        }else if(error.response.status === 422){
          notification.error({
            message: error.response.data.errors.message[0],
          });
        }
      } else {
        serverError = true
      }
      if (serverError) {
        notification.error({
          message: `Server error  ${error.response ? error.response.status : ''}`,
        });

      }
      return Promise.reject(error)
    })
  }

  render() {
    const { context } = this.props
    if (context.state.login_token) {
      axios.defaults.headers.common["Authorization"] = `Bearer ${context.state.login_token}`
    }
    return (
      <Router>
        <React.Suspense fallback={loading()}>
          <Switch>
            <Route path="/login/:redirect?" name='Login' render={props => context.state.is_logged ?
              <Redirect to='/' />
              : <Login {...props} />
            } />
            <Route path="/" name="Home" render={props => context.state.is_logged ?
              <DefaultLayout {...props} />
              : <Redirect to={`/login/${props.location.pathname.substring(nthIndex(props.location.pathname, '/', 1) + 1, props.location.pathname.length)}${props.location.search}`} />
            } />
          </Switch>
        </React.Suspense>
      </Router>
    );
  }
}

class App extends Component {
  constructor(props) {
    super(props);
    this.state = {}
  }
  render() {
    return (
      <React.Fragment>
        <AppContextProvider>
          <Router>
            <Route path="/" render={
              (props) => <AppContext.Consumer>
                {(context) => {
                  return <Main {...props} context={context} />
                }}
              </AppContext.Consumer>} />
          </Router>
        </AppContextProvider>
      </React.Fragment>
    );
  }
}

export default App;
