import React, { memo, useEffect } from "react";
import PropTypes from "prop-types";
import Lottie from "react-lottie";
import SabqLoader from "../../assets/lottie/loader.json";

const Loader = memo(({ isLoading, children, className, style }) => {
  useEffect(() => () => {
    // reset body style when unmount
    document.body.style.overflow = "auto";
  });

  if (!isLoading) {
    document.body.style.overflow = "auto";
    return children;
  }

  document.body.style.overflow = "hidden";
  return (
    <div
      className={className}
      style={{
        display: "flex",
        backgroundColor: "white",
        zIndex: 1000,
        minHeight: 300,
        height: "60vh",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        position: "absolute",
        left: 0,
        right: 0,
        top: 0,
        bottom: 0,
        ...style,
      }}
    >
      <div className="loader position-absolute">
        <Lottie
          options={{
            animationData: SabqLoader,
            loop: true,
          }}
          style={{
            width: "120vw",
            height: "100vh",
            display: "flex",
            // position: 'absolute',
            left: "50%",
            top: "50%",
            // transform: 'translate(-50%, -50%)'
          }}
        />
      </div>
    </div>
  );
});

Loader.propTypes = {
  isLoading: PropTypes.bool.isRequired,
  children: PropTypes.func,
  className: PropTypes.string,
  style: PropTypes.object,
  size: PropTypes.number,
};

Loader.defaultProps = {
  className: "",
  style: {},
  size: 80,
  children: null,
};

export default Loader;
